<template>
  <div class="category-group">
    <swiper ref="mySwiper" class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in productCategoryList" :key="index">
        <div
          :class="['category-items', setCateClass(item.category_id, index)]"
          :style="setStyle(item)"
          @mouseover="setHoverStyle(item)"
        >
          <a @click="setCate(item.category_id)">{{ item.name }}</a>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { getCatogory } from '@/api/shop'
import { getTitle } from '@/utils/tool'

export default {
  name: 'InterNav',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      category_id: this.$route.params.cate
        ? parseInt(this.$route.params.cate)
        : 0,
      productCategoryList: false,
      swiperOption: {
        slidesPerView: 6.5,
        spaceBetween: 6,
        // freeMode: true,
        observer: true,
        observeParents: true,
        breakpoints: {
          767: {
            observer: true,
            observeParents: true,
            slidesPerView: 3.5,
            spaceBetween: 10
          }
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  created() {
    // 標籤
    getCatogory().then(response => {
      this.productCategoryList = [
        {
          name: '全部',
          category_id: 0
        }
      ]
      this.productCategoryList = [
        ...this.productCategoryList,
        ...response.data.data
      ]
      this.getcataTitle()
    })
  },
  updated() {
    this.getcataTitle()
  },
  methods: {
    getcataTitle() {
      const isfind = this.productCategoryList.find(e => {
        if (e.category_id.toString() === this.category_id.toString()) {
          return e
        }
      })
      getTitle(`商品列表(${isfind.name})`)
    },
    setCate(category_id) {
      this.category_id = category_id
      this.$emit('setCate', category_id)
    },
    setStyle(item) {
      if (this.category_id === item.category_id && item.color !== undefined) {
        item.color = item.color != null ? item.color : '#f39800'
        return 'background-color:' + item.color
      } else {
        return ''
      }
    },
    setHoverStyle(item) {
      if (item.color !== undefined) {
        return 'background-color:' + item.color
      } else {
        return ''
      }
    },
    setCateClass(category_id, index) {
      if (category_id === 0 && this.category_id === 0) {
        return 'active'
      } else {
        return ''
      }

      // if (category_id === undefined && this.category_id === 0) {
      //   // this.swiper.slideTo(index)
      //   return 'active'
      // } else {
      //   if (this.category_id === category_id) {
      //     // this.swiper.slideTo(index)
      //     return 'active'
      //   } else {
      //     return ''
      //   }
      // }

      // return setCateClass(category_id) + ' ' + (this.category_id === category_id ? 'active' : '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/common.scss';
.category-group .swiper-slide {
  width: auto !important;
}
.category-group {
  padding: 0;
  margin: 1rem auto;
  .category-items {
    white-space: nowrap;
    text-align: center;
    border-radius: 50px;
    margin: 0;
    padding: 5px 12px 6px;
    line-height: 1.15rem;
    background: rgba($black_color, $alpha: 0.25);
  }
  .category-items a {
    color: rgba($white-color, $alpha: 1);
    font-size: 1;
  }
  .swiper-slide:nth-of-type(1) .category-items:hover,
  .swiper-slide:nth-of-type(1) .category-items:active,
  .swiper-slide:nth-of-type(1) .category-items:focus,
  .swiper-slide:nth-of-type(1) .category-items.active {
    background: rgba($allitem_color, $alpha: 1);
  }
  .swiper-slide:nth-of-type(2) .category-items:hover,
  .swiper-slide:nth-of-type(2) .category-items:active,
  .swiper-slide:nth-of-type(2) .category-items:focus,
  .swiper-slide:nth-of-type(2) .category-items.active {
    background: rgba($eating_item, $alpha: 1);
  }
  .swiper-slide:nth-of-type(3) .category-items:hover,
  .swiper-slide:nth-of-type(3) .category-items:active,
  .swiper-slide:nth-of-type(3) .category-items:focus,
  .swiper-slide:nth-of-type(3) .category-items.active {
    background: rgba($hotspring_color, $alpha: 1);
  }
  .swiper-slide:nth-of-type(4) .category-items:hover,
  .swiper-slide:nth-of-type(4) .category-items:active,
  .swiper-slide:nth-of-type(4) .category-items:focus,
  .swiper-slide:nth-of-type(4) .category-items.active {
    background: rgba($living_item, $alpha: 1);
  }
  .swiper-slide:nth-of-type(5) .category-items:hover,
  .swiper-slide:nth-of-type(5) .category-items:active,
  .swiper-slide:nth-of-type(5) .category-items:focus,
  .swiper-slide:nth-of-type(5) .category-items.active {
    background: rgba($spa_item, $alpha: 1);
  }
  .swiper-slide:nth-of-type(6) .category-items:hover,
  .swiper-slide:nth-of-type(6) .category-items:active,
  .swiper-slide:nth-of-type(6) .category-items:focus,
  .swiper-slide:nth-of-type(6) .category-items.active {
    background: rgba($amusement_item, $alpha: 1);
  }
  .swiper-slide:nth-of-type(7) .category-items:hover,
  .swiper-slide:nth-of-type(7) .category-items:active,
  .swiper-slide:nth-of-type(7) .category-items:focus,
  .swiper-slide:nth-of-type(7) .category-items.active {
    background: rgba($traffic_item, $alpha: 1);
  }
  .swiper-slide:nth-of-type(8) .category-items:hover,
  .swiper-slide:nth-of-type(8) .category-items:active,
  .swiper-slide:nth-of-type(8) .category-items:focus,
  .swiper-slide:nth-of-type(8) .category-items.active {
    background: rgba($wifi_sim_item, $alpha: 1);
  }
  .swiper-slide:nth-of-type(9) .category-items:hover,
  .swiper-slide:nth-of-type(9) .category-items:active,
  .swiper-slide:nth-of-type(9) .category-items:focus,
  .swiper-slide:nth-of-type(9) .category-items.active {
    background: rgba($travil_item, $alpha: 1);
  }
  .swiper-slide:nth-of-type(10) .category-items:hover,
  .swiper-slide:nth-of-type(10) .category-items:active,
  .swiper-slide:nth-of-type(10) .category-items:focus,
  .swiper-slide:nth-of-type(10) .category-items.active {
    background: rgba($allitem_color, $alpha: 1);
  }
}
</style>
